<template>
  <div class="row justify-content-center">
    <!--repeatable block-->
    <div class="col-sm-11 mt-2" v-for="(item, index) in item_server_data" :key="index">
      <div class="card card-horizontal-list laurenGreen">
        <div class="card-body">
          <div class="hover-slidess">
            <div class="form-check outliner">
              <input
                class="form-check-input"
                type="checkbox"
                :value="item.item_no"
                v-model="selectedItems"
                @change="addToRecentItems(item.item_no)"
              />
            </div>
          </div>
          <div class="card-content">
            <span class="card-count">{{ index + 1 }}.</span>
            <ul class="label-list">
              <li class="list-title">
                <label>C:</label><span class="list-text">{{ item.category_type }}</span>
              </li>
              <li class="sub-title">
                <label>I:</label><span class="list-text">{{ item.name }}</span>
              </li>
              <li>
                <label>I.No:</label><span class="list-text">{{ item.item_no }}</span>
              </li>
              <li class="mt-2">
                <label>D:</label><span class="list-text">{{ item.description }}</span>
              </li>
            </ul>
          </div>
          <div class="content-option">
            <ul class="cmn-vrList">
              <li>
                <a href="#"><i class="fa fa-cog"></i> </a>
              </li>
              <li>
                <a href="#"><i class="fas fa-running"></i> </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-footer has-action">
          <div class="ellipsis-dropdown dropdown">
            <button
              class="btn dropdown-toggle no-caret"
              type="button"
              id="card-dropAction-1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fas fa-ellipsis-v"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="card-dropAction-1">
              <li>
                <a class="dropdown-item edit" href="#"><i class="fa fa-edit"></i> </a>
              </li>
              <li>
                <a
                  class="dropdown-item delete"
                  @click="deleteItem(item.id, item.item_no)"
                  href="#"
                  ><i class="fa fa-times"></i>
                </a>
              </li>
            </ul>
          </div>
          <div class="footer-list-wraper">
            <ul class="footer-list">
              <li>Quantity</li>
              <li class="count">{{ item.quantity }}</li>
            </ul>

            <ul class="footer-list highlight">
              <li>Rate</li>
              <li class="count">&pound;{{ item.unit_price }}</li>
            </ul>

            <ul class="footer-list _big">
              <li>Price</li>
              <li class="count">&pound;{{ item.unit_price * item.quantity }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--ended repeatable block-->
  </div>
</template>

<script>
export default {
  name: "ItemListOnlyForInvoice",
  data() {
    return {
      loadingInstance: null,
      item_server_data: [],
      type: "",
      categories: [],
      selectedItems: [],
    };
  },
  mounted() {
    this.runMountedFunctions();
  },
  methods: {
    runMountedFunctions() {
    //   this.$store.dispatch("item/fetchCategory").then((res) => {
        // this.categories = res;
        this.getData();
    //   });
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("item/getList", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.item_server_data = res.response;
            this.$toast.success("Fetch data Successful.");
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.item_server_data = [];
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteItem(item_id, item_no = "") {
      this.showLoading();
      this.removeItemFromRecentList(item_no);
      this.$store
        .dispatch("item/deleteItem", { item_id: item_id })
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Delete data Successful.");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.getData();
        });
    },
    addToRecentItems(selectedItems) {
      this.$store.commit("item/addToRecentItems", selectedItems);
    },
  },
};
</script>
