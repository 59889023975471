<template>
  <form @submit.prevent="handleSubmit" class="base-form">
    <h5 class="form-title">Limited Company contact details</h5>
    <img :src="image_logo_server" alt="" />
    <div class="mb-3">
      <input
        type="text"
        name="comp_name"
        v-model="form.companyname"
        required
        class="form-control"
        placeholder="Name/Company Name*"
      />
      <span v-if="errors.companyname" class="text-danger">{{ errors.companyname }}</span>
    </div>

    <div class="mb-3">
      <select v-model="form.business_category_id" required class="form-select">
        <option disabled selected value="">Business Category*</option>
        <option
          v-for="option in business_category_options"
          :value="option.id"
          :key="option.id"
        >
          {{ option.name }}
        </option>
      </select>
      <span v-if="errors.business_category_id" class="text-danger">{{ errors.business_category_id }}</span>
    </div>
    <div class="row flex-row flex-nowrap">
      <div class="d-flex flex-fill flex-column">
        <div class="mb-3">
          <input
            type="text"
            v-model="form.mobile"
            required
            class="form-control"
            placeholder="Mobile no*"
          />
          <span v-if="errors.mobile" class="text-danger">{{ errors.mobile }}</span>
        </div>
        <div class="mb-3">
          <input
            type="text"
            v-model="form.phone1"
            class="form-control"
            placeholder="Phone no"
          />
          <span v-if="errors.phone1" class="text-danger">{{ errors.phone1 }}</span>
        </div>
      </div>

      <div class="btn-file-wrapper">
        <label for="logo-file" class="btn btn-file">
          <span class="file-icon">
            <i class="fa fa-plus"></i>
          </span>
        </label>

        <input
          type="file"
          id="logo-file"
          ref="uploads"
          style="display: none"
          @change="handleFileSelect(event)"
        />
        <span class="form-text">Upload Logo</span>
      </div>
    </div>

    <!-- <div class="row d-flex align-items-center justify-content-center m-0 mb-2">
      <button type="button" name="toggleDrop" class="btn btn-toggleDrop" data-bs-toggle="collapse"
        data-bs-target="#formMore" aria-expanded="true" aria-controls="formMore">
        <span><i class="fas fa-chevron-down"></i> </span>
        <span><i class="fas fa-chevron-down"></i> </span>
      </button>
    </div> -->

    <div class="collapse show" id="formMore">
      <div class="mb-3">
        <input
          type="text"
          v-model="form.postal_address"
          class="form-control"
          placeholder="Postal address / Street 1"
        />
        <span v-if="errors.postal_address" class="text-danger">{{ errors.postal_address }}</span>
      </div>

      <div class="mb-3">
        <input
          type="text"
          v-model="form.street2"
          class="form-control"
          placeholder="Street 2"
        />
        <span v-if="errors.street2" class="text-danger">{{ errors.street2 }}</span>
      </div>
      <!-- <div class="mb-3">
        <input type="text" v-model="form.state" class="form-control" placeholder="State" />
      </div> -->

      <div class="row g-3">
        <div class="col-sm-12 col-md-12 col-lg-7 mb-3">
          <select v-model="form.state" class="form-select" placeholder="Country">
            <option selected disabled>State</option>
            <option v-for="option in states" :value="option.name" :key="option.id">
              {{ option.name }}
            </option>
          </select>
          <span v-if="errors.state" class="text-danger">{{ errors.state }}</span>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-5 mb-3">
          <input
            type="text"
            v-model="form.zip"
            class="form-control"
            placeholder="Zip/Postal Code"
          />
          <span v-if="errors.zip" class="text-danger">{{ errors.zip }}</span>
        </div>
      </div>
      <div class="mb-3">
        <input
          type="text"
          v-model="form.website"
          class="form-control"
          placeholder="Website"
        />
        <span v-if="errors.website" class="text-danger">{{ errors.website }}</span>
      </div>
    </div>

    <div class="form-footer d-flex align-items-end mt-4">
      <button type="submit" role="button" :disabled="loading" class="btn btn-indigo">
        Next
      </button>
    </div>
  </form>
</template>
<script>
import { BUSINESS_CATEGORIES, COUNTRIES_LIST } from "../../config/url";

export default {
  name: "SoleTraderFormCompany",
  data() {
    return {
      loadingInstance: null,
      loading: false,
      countries: null,
      business_categories: null,
      states: "",
      form: {
        fullname: "",
        business_category_id: "",
        postal_address: "",
        street2: "",
        state: "",
        zip: "",
        phone1: "",
        mobile: "",
        website: "",
        companyname: "",
        image: "",
        is_logo: "N",
      },
      image_logo_server: "",
    };
  },
  mounted() {
    let user_details_local = this.$store.getters["auth/getUserData"];
    this.country_id = user_details_local.country_id;
    this.form.fullname = user_details_local.welcomename;

    this.form.business_category_id = user_details_local.business_category_id;
    this.form.postal_address = user_details_local.address_details.postal_address;
    this.form.street2 = user_details_local.address_details.street2;
    this.form.state = user_details_local.address_details.state;
    this.form.zip = user_details_local.address_details.zip;
    this.form.phone1 = user_details_local.address_details.phone1;
    this.form.mobile = user_details_local.address_details.phone2;
    this.form.website = user_details_local.address_details.website;
    this.form.companyname = user_details_local.company_name;
    if (user_details_local.logourl != "N?A") {
      this.image_logo_server = user_details_local.logourl;
    }

    this.$nextTick(() => {
      this.fetchBusinessCategories();
      this.fetchStateByCountry();
    });
  },
  computed: {
    country_options() {
      if (this.countries) {
        return this.countries.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
    business_category_options() {
      if (this.business_categories) {
        return this.business_categories.map((v) => {
          return {
            id: v.id,
            name: v.name,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    async fetchBusinessCategories() {
      this.business_categories = await this.axios
        .get(BUSINESS_CATEGORIES)
        .then(function (res) {
          return res.data.message;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async fetchCountries() {
      this.countries = await this.axios
        .get(COUNTRIES_LIST)
        .then(function (res) {
          return res.data.response;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchStateByCountry() {
      this.showLoading();

      this.$store
        .dispatch("client/fetchStateByCountry", { country_id: this.country_id })
        .then((res) => {
          if (res.status == 1) {
            this.states = res.response;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    validateForm() {
      this.errors = {};  // Clear previous errors
      if (!this.form.business_category_id) this.errors.business_category_id = "Business category is required";
      if (!this.form.postal_address) this.errors.postal_address = "Postal address is required";
      if (!this.form.street2) this.errors.street2 = "Street name is required";
      if (!this.form.state) this.errors.state = "State is required";
      if (!this.form.zip) this.errors.zip = "Zip code is required";
      if (!this.form.phone1) this.errors.phone1 = "Phone number is required";
      if (!this.form.mobile) this.errors.mobile = "Mobile number is required";
      if (!this.form.website) this.errors.website = "Website is required";
      if (!this.form.companyname) this.errors.companyname = "Company name is required";
      return Object.keys(this.errors).length === 0;  // Return true if no errors
    },
    handleSubmit() {
      this.showLoading();
      if (this.form.is_logo == "N") {
        delete this.form.is_logo;
      }

      if (this.validateForm()) {
        this.showLoading();
        this.$store
          .dispatch("company/save", this.form)
          .then((res) => {
            if (res.status === 1) {
              this.$toast.success("Registration completed.");
              this.$router.push({ name: "user-dashboard" });
            } else {
              throw new Error(res);
            }
          })
          .catch((err) => {
            console.log(err);
            this.$toast.error("Something went wrong. Please try again.");
          })
          .finally(() => {
            this.hideLoading();
          });
      } else {
        this.$toast.error("Please fill in all required fields.");
      }
    },
    handleFileSelect() {
      this.form.image = [...this.$refs.uploads.files][0];
      this.form.is_logo = "Y";
    },
  },
};
</script>
