import companyService from "../services/company.service";

const initialState = {
    fullname: '',
    business_category_id: '',
    postal_address: '',
    street2: '',
    state: '',
    zip: '',
    phone1: '',
    mobile: '',
    website: '',
    companyname: '',
    companytype: '',
    image: '',
    is_logo: ''
}

export const company = {
    namespaced: true,
    state: initialState,
    actions: {
        save({ commit, rootCommit }, company) {
            return companyService.register(company).then(
                response => {
                    commit('save', company);
                    // Dispatch to auth module to set company details
                    rootCommit('auth/setCompanyDetails', {
                        companyname: company.companyname,
                        business_category_id: company.business_category_id
                    }, { root: true });
                    return Promise.resolve(response.data);
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }
    },
    mutations: {
        save(state, company) {
            state = Object.assign(state, company);
        }
    }
};